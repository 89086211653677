<template>
	<div class="row">
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row m-0">
                  <div class="col-12 mb-3 px-1" :class="dadosUsuario.isAdmin || dadosUsuario.isSuporte ? 'col-lg-9' : 'col-lg-11'">
                     <label><i class="far fa-search color-theme me-1"></i> {{ $t('admAjuda.pesquisar') }}</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" />
                  </div>
                  <div class="col-lg-1 col-6 mb-3 px-1 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="searchDuvida">
                        <i class="far fa-search font-12"></i>
                     </button>
                  </div>
                  <div class="col-lg-2 col-6 mb-3 px-1 align-self-end" v-if="dadosUsuario.isAdmin || dadosUsuario.isSuporte">
                     <button type="button" class="btn btn-primary w-100" @click="editarAjuda">
                        <i class="far fa-plus font-12 me-1"></i> {{ $t('admAjuda.adicionar') }}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

		<!-- Resultados -->
      <div class="accordion" id="accordionExample">
         <div class="accordion-item mb-1" v-for="(item, index) in pesquisa.resultado" :key="index">
            <h2 class="accordion-header" :id="'heading' + index">
               <button class="accordion-button py-4 weight-500 collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
                  <i class="far fa-file color-theme font-13 me-2"></i><span class="weight-600 font-15">{{ item.titulo }}</span>
               </button>
            </h2>
            <div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index" data-bs-parent="#accordionExample">
               <div class="accordion-body">
                  <div class="row align-items-center">
                     <div class="col">
                        <h1 class="text-secondary font-14 mb-1"><i class="far fa-books font-12 me-1"></i> {{ $t('admAjuda.descricao') }}</h1>
                     </div>
                     <div class="w-max-content d-flex" v-if="dadosUsuario.isAdmin || dadosUsuario.isSuporte">
                        <button class="btn btn-primary me-2" @click="editarAjuda(item)"><i class="far fa-pencil font-12"></i></button>
								<button class="btn btn-primary me-2" @click="deletarAjuda(item)"><i class="far fa-trash font-12"></i></button>
                     </div>
                     <div class="col-12 mt-2">{{ item.descricao }}</div>
                  </div>
               </div>
            </div>
         </div>
      </div>

		<!-- modalFAQ -->
		<div class="modal fade" id="modalFAQ" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalEditarFAQLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="modalEditarFAQLabel" v-if="pesquisa.resultado != null && ajuda.id != null">{{ $t('admAjuda.editarTitulo') }}</h5>
						<h5 class="modal-title" id="modalEditarFAQLabel" v-else>{{ $t('admAjuda.adicionarTitulo') }}</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
					</div>
					<div class="modal-body">
                  <div class="card mb-0">
                     <div class="card-body">
                        <div class="row m-0">
                           <div class="col-12 px-1 mb-3">
                              <label><i class="fas fa-file color-theme me-1"></i> {{ $t('admAjuda.campoTitulo') }}</label>
                              <input type="text" class="form-control" maxlength="200" v-model="ajuda.titulo" />
                           </div>
                           <div class="col-12 px-1">
                              <label class="mb-1"><i class="fas fa-tag color-theme me-1"></i> {{ $t('admAjuda.campoDescricao') }}</label>
                              <textarea style="height: 200px;" class="form-control" v-model="ajuda.descricao"></textarea>
                           </div>
                        </div>
                     </div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="saveDuvida">{{ $t('admAjuda.botaoSalvar') }}</button>
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('admAjuda.botaoFechar') }}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
	data : function () {
		return {
			pesquisa: {'nome': '', 'resultado': []},
			ajuda: {}
		}
	},
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest
		}),
	},
	methods: {
		searchDuvida : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
			this.pesquisa.resultado = []

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchAllFAQs',
				params: {
               nome: this.pesquisa.nome
            }
         }).then(response => {
				this.pesquisa.resultado = response.data

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
		saveDuvida : function () {
         if (this.ajuda.titulo == null || this.ajuda.titulo.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t('admAjuda.validacaoTitulo')
            })

            return
         } else if (this.ajuda.descricao == null || this.ajuda.descricao.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t('admAjuda.validacaoDescricao')
            })

            return
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/saveFAQ',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.ajuda

         }).then(() => {
            this.searchDuvida()
				$('#modalFAQ').modal('hide')

            this.$toast.fire({
               icon: 'success',
               title: this.$t('admAjuda.salvarSucesso')
            })
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalFAQ').modal('hide')
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
		editarAjuda : function (item) {
         this.ajuda = JSON.parse(JSON.stringify(item));
         $('#modalFAQ').modal('show')
      },
		deletarAjuda : function (item) {
         Swal.fire({
            icon: 'warning',
            title: this.$t('admAjuda.deletarDesc'),
            showCancelButton: true,
            confirmButtonText: this.$t('admAjuda.botaoConfirmar'),
            cancelButtonText: this.$t('admAjuda.botaoCancelar')

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)
               
               this.$axios({
                  method: 'post',
                  url: this.urlRest +'servicosAdmin/deleteFAQ',
                  params: {
                     id: item.id
                  }
               }).then(() => {
                  this.searchDuvida()

                  this.$toast.fire({
                     icon: 'success',
                     title: this.$t('admAjuda.deletarSucesso')
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t('sessaoExpirada')
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
		},
	},
	mounted() {
		this.searchDuvida()
	}
}

</script>
